<template>
    <div class="shippingManage-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>

    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: "发货管理",
                    isNotShowSelection: true, //不显示表格列表多选框
                    search: [{
                            key: "type",
                            label: "商家名称",
                            type: "select",
                            opt: {
                                list: []
                            }
                        },
                        {
                            key: "code",
                            label: "货柜型号",
                            type: "select",
                            opt: {
                                list: []
                            }
                        },
                        {
                            key: "mac",
                            label: "货柜MAC",
                        }
                    ],
                    columns: [{
                            label: "货柜序列号",
                            key: "name"
                        },
                        {
                            label: "货柜MAC",
                            key: "mac"
                        },
                        {
                            label: "商家名称",
                            key: "createdTime1"
                        },
                        {
                            label: "货柜型号",
                            key: "createdBy"
                        },
                        {
                            label: "货柜名称",
                            key: "remarks"
                        },
                        {
                            label: "货柜位置",
                            key: "stateStr1"
                        },
                        {
                            label: "发货时间",
                            key: "createdTime"
                        },
                        {
                            label: "设备状态",
                            key: "stateStr"
                        },
                    ],
                    buttons: [{
                        type: 0,
                        on() {
                            _this.$router.push('/main/shippingManage/detail')
                        }
                    }]
                },
                
            };
        },
        created() {
            console.log("shippingManage-list created!!");
        },
        activated() {
            console.log("shippingManage-list activated!!");
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                this.post("mall-service/category/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    res.data.data.forEach((item, index) => {

                    });
                    opt.cb(res.data);
                });
            },
            
            

        }
    };
</script>
<style lang="scss" scope>
  
</style>